
import { computed, ref, defineComponent, PropType, watch, nextTick } from "vue";
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
} from "@element-plus/icons";
import useEmit from "@/hooks/Emit";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "基本操作",
    },
    canCollapsed: {
      type: Boolean,
      default: false,
    },
    searchModel: {
      type: Array as PropType<Array<FormItem>>,
      default: null,
    },
    defaultCollapsedState: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["doSearch", "resetSearch", 'getSelect', 'getProxy'],
  setup(props, { emit }) {
    const showSearchContent = ref(props.defaultCollapsedState);

    const showArrow = computed(() => {
      return (
        props.canCollapsed &&
        !!props.searchModel &&
        props.searchModel.length !== 0
      );
    });

    const collapsedState = computed(() => {
      return showSearchContent.value && showArrow.value;
    });
    const emitter = useEmit();
    watch(
      () => collapsedState.value,
      (newVal) => {
        nextTick(() => {
          emitter?.emit("table-collapse-transition", newVal);
        });
      }
    );

    const filterSearchModel = computed(() => {
      if (!props.searchModel) return [];
      const tmp: Array<any> = [];
      props.searchModel.forEach((it, index) => {
        const tmpIndex = Math.floor(index / 4);
        if (!tmp[tmpIndex]) {
          tmp[tmpIndex] = [];
        }
        tmp[tmpIndex].push(it);
      });
      if (tmp.length > 0) {
        const lastItem = tmp[tmp.length - 1];
        if (lastItem.length < 3) {
          lastItem.push({
            type: "action",
          });
        } else {
          const actions = [{ type: "action" }];
          tmp[tmp.length] = actions;
        }
      }
      return tmp;
    });
    function collapsed() {
      showSearchContent.value = !showSearchContent.value;
    }
    function doSearch() {
      emit("doSearch");
    }
    function doResetSearch() {
      props.searchModel.forEach((it) => {
        it.value = "";
      });
      emit("resetSearch");
    }
    function handleSelectChange(value: any) {
      emit("getSelect", value);
    }


    return {
      showSearchContent,
      showArrow,
      collapsedState,
      filterSearchModel,
      collapsed,
      doSearch,
      doResetSearch,
      SearchIcon,
      RefreshIcon,
      handleSelectChange,
    };
  },
});
