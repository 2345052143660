// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-container[data-v-37dfd6fc] {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n  background-color: #E0E7EF;\n  position: relative;\n}\n.login-container .top-left[data-v-37dfd6fc] {\n  position: absolute;\n  top: 42px;\n  left: 62px;\n  display: flex;\n  line-height: 40px;\n  color: #5F7692;\n  font-size: 16px;\n  font-weight: 600;\n}\n.login-container .top-left img[data-v-37dfd6fc] {\n  width: 40px;\n  height: 40px;\n  margin-right: 10px;\n}\n.login-container .footer-text[data-v-37dfd6fc] {\n  position: absolute;\n  bottom: 87px;\n  right: 91px;\n  width: 480px;\n  text-align: center;\n  color: #5F7692;\n  font-size: 18px;\n  font-weight: 600;\n}\n.login-container .right[data-v-37dfd6fc] {\n  background-color: #fff;\n  width: 480px;\n  margin-right: 91px;\n  border-radius: 20px;\n}\n.login-container .right .form-wrapper[data-v-37dfd6fc] {\n  border-radius: 20px;\n  border: 0.5px solid #EAEAEA;\n  padding: 30px 26px 30px 26px;\n}\n.login-container .right .form-wrapper .title[data-v-37dfd6fc] {\n  color: #525B63;\n  font-size: 24px;\n  font-weight: 550;\n  margin-bottom: 25px;\n}\n.login-container .right .form-wrapper .item-wrapper[data-v-37dfd6fc] {\n  margin-top: 20px;\n}\n.login-container .right .form-wrapper .login[data-v-37dfd6fc] {\n  width: 75%;\n  height: 40px;\n  border-radius: 40px;\n  background: #1783E2;\n  margin: 0 auto;\n  margin-top: 40px;\n  display: block;\n}", ""]);
// Exports
module.exports = exports;
