
 export const RoutesList = [
   {
     menuUrl: "/custom",
     menuName: "客户管理",
     icon: "User",
     key: "/custom",
     tip: "new",
     name: "客户管理",
     parentPath: '',
     children: [
       {
         parentPath: '/userList',
         menuUrl: "/custom/userList",   
         key: "/custom/userList",
         menuName: "客户列表",
         name: "客户列表",
         children: [
          {
              parentPath: '/userList',
              menuUrl: "/custom/userList",   
              key: "/custom/userList/add",
              menuName: "手动添加按钮",
              hidden: true,
              name: "客户列表",
          },
          {
              parentPath: '/userList',
              menuUrl: "/custom/userList",   
              key: "/custom/userList/edit",
              menuName: "修改按钮",
              name: "客户列表",
              hidden: true,
          },
          {
              parentPath: '/userList',
              menuUrl: "/custom/userList",   
              key: "/custom/userList/moeny",
              menuName: "出入金按钮",
              name: "客户列表",
              hidden: true,
          },
          {
              parentPath: '/userList',
              menuUrl: "/custom/userList",   
              key: "/custom/userList/record",
              menuName: "开户状态审核权限",
              hidden: true,
              name: "客户列表",
          },
          {
              parentPath: '/userList',
              menuUrl: "/custom/userList",   
              key: "/custom/userList/editRecord",
              menuName: "修改返佣",
              hidden: true,
              name: "客户列表",
          },
          {
              parentPath: '/userList',
              menuUrl: "/custom/userList",   
              key: "/custom/userList/del",
              menuName: "删除",
              hidden: true,
              name: "客户列表",
          },
         ]
       },
       {
          parentPath: '/rebateList',
          menuUrl: "/custom/rebateList",
          key: "/custom/rebateList",
          menuName: "返佣统计",
          name: "返佣统计",
       },
       {
          parentPath: '/transaction',
          menuUrl: "/custom/transaction",
          key: "/custom/transaction",
          menuName: "交易统计",
          name: "交易统计",
       },

     ],
   },
   {
      menuUrl: "/earlyWarning",
      menuName: "风控管理",
      icon: "Bell",
      tip: "new",
      key: "/earlyWarning",
      name: "风控管理",
      parentPath: '',
      children: [
        {
          parentPath: '/earlyWarning',
          menuUrl: "/earlyWarning/warningList",
          key: "/earlyWarning/warningList",
          menuName: "劣后预警",
          name: "劣后预警",
         
        }
      ]
   },
   {
      menuUrl: "/agent",
      menuName: "代理商管理",
      icon: "Connection",
      tip: "new",
      key: "/agent",
      parentPath: '',
      name: "代理商管理",
      children: [
        {
          parentPath: '/agent',
          menuUrl: "/agent/agentList",
          key: "/agent/agentList",
          menuName: "代理商列表",
          name: "代理商列表",
          children: [
            {
                parentPath: '/agent',
                menuUrl: "/agent/agentList",
                key: "/agent/agentList/edit",
                menuName: "修改按钮",
                name: "代理商列表",
                hidden: true,
            }
          ]
        },
        {
          parentPath: '/agent',
          menuUrl: "/agent/withdrawalList",
          key: "/agent/withdrawalList",
          menuName: "提现管理",
          name: "提现管理",
        }
      ]
   },
   {
     menuUrl: "/finmanage",
     menuName: "财务管理",
     icon: "Collection",
     tip: "new",
     key: "/finmanage",
     name: "财务管理",
     parentPath: '',
     children: [
       {
         parentPath: '/finmanage',
         menuUrl: "/finmanage/auditing",
          key: "/finmanage/auditing",
         menuName: "提现审核",
          name: "提现审核",
       },
     ],
   },
    {
      menuUrl: "/basicSettings",
      menuName: "基础设置",
      icon: "Edit",
      tip: "new",
      key: "/basicSettings",
      name: "基础设置",
      parentPath: '',
      children: [
        {
          parentPath: '/basicSettings',
          menuUrl: "/basicSettings/reveSetting",
          key: "/basicSettings/reveSetting",
          menuName: "收益设置",
          name: "收益设置",
        },
        {
          parentPath: '/basicSettings',
          menuUrl: "/basicSettings/perInfo",
          key: "/basicSettings/perInfo",
          menuName: "个人信息",
          name: "个人信息",
        },
      ],
   },
   {
     menuUrl: "/system",
     menuName: "系统管理",
     icon: "SettingIcon",
     key: "/system",
     tip: "new",
     parentPath: '',
     name: "系统管理",
     children: [
       {
         parentPath: '/system',
         menuUrl: "/system/role",
         menuName: "角色管理",
         key: "/system/role",
         name: "角色管理",
       },
        {
         parentPath: '/admin',
         menuUrl: "/system/admin",
         menuName: "管理员管理",
         key: "/system/admin",
          name: "管理员管理",
       },
        {
          parentPath: '/system',
          menuUrl: "/system/structure",
          menuName: "部门架构",
          key: "/system/structure",
          name: "部门架构",
        },
     ],
   }];