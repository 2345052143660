import { Directive } from 'vue';
import { hasPermission } from '../../utils/router'; // 导入 hasPermission 函数

const permission: Directive = {
  mounted(el, binding) {
    const permissionKeys = binding.value;
    if (!hasPermission(permissionKeys)) {
      // el.style.display = 'none';
       el.parentNode?.removeChild(el);
    }
  }
}

export default permission;
