
import { Refresh as RefreshIcon } from "@element-plus/icons";
import { computed, defineComponent, PropType, reactive } from "vue";

export default defineComponent({
  name: "TableFooter",
  emits: ["pageChanged", "refresh"],
  props: {
    pageSizes: {
      type: Array as PropType<Array<number>>,
      default: () => {
        return [10, 20, 30, 40];
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    showRefresh: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String as PropType<"left" | "center" | "right">,
      default: "center",
    },
  },
  setup(props, { emit }) {
    const pageModel = reactive({
      page: 1,
      size: props.pageSize,
      totalSize: 0,
    });
    const pageSizeChanged = (pageSize: number) => {
      pageModel.size = pageSize;
      pageModel.page = 1;
      emit("pageChanged", pageModel);
    };
    const currentChanged = (page: number) => {
      pageModel.page = page;
      emit("pageChanged", pageModel);
    };
    const withPageInfoData = (otherParams = {}) => {
      return {
        ...otherParams,
        page: pageModel.page,
        size: pageModel.size,
      };
    };
    const resetPage = () => {
      pageModel.page = 1;
      pageModel.size = 10;
    };
    const setTotalSize = (totalSize: number) => {
      pageModel.totalSize = totalSize;
    };
    const setPageSize = (pageSize: number) => {
      pageModel.size = pageSize;
    };
    const placement = computed(() => {
      switch (props.position) {
        case "left":
          return "justify-start";
        case "center":
          return "justify-center";
        case "right":
          return "justify-end";
        default:
          return "";
      }
    });
    const onRefresh = () => {
      emit("refresh");
    };
    return {
      pageModel,
      placement,
      pageSizeChanged,
      currentChanged,
      withPageInfoData,
      setTotalSize,
      setPageSize,
      onRefresh,
      RefreshIcon,
      resetPage,
    };
  },
});
