import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    "body-style": { padding: 0, width: '100%' },
    class: "table-footer-container",
    id: "tableFooterContainer",
    shadow: "never"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex align-center", [_ctx.placement]])
      }, [
        _createVNode(_component_el_pagination, {
          "current-page": _ctx.pageModel.page,
          "page-sizes": _ctx.pageSizes,
          "page-size": _ctx.pageModel.size,
          layout: _ctx.$isMobile
    ? 'total, pager, jumper'
    : 'total, sizes, prev, pager, next, jumper'
    ,
          total: _ctx.pageModel.totalSize,
          onSizeChange: _ctx.pageSizeChanged,
          onCurrentChange: _ctx.currentChanged
        }, null, 8 /* PROPS */, ["current-page", "page-sizes", "page-size", "layout", "total", "onSizeChange", "onCurrentChange"]),
        (_ctx.showRefresh)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              style: {"margin-left":"15px","width":"30px","height":"30px"},
              circle: "",
              size: "small",
              icon: _ctx.RefreshIcon,
              type: "primary",
              onClick: _ctx.onRefresh
            }, null, 8 /* PROPS */, ["icon", "onClick"]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}