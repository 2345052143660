import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fcb30c0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TableFooter = _resolveComponent("TableFooter")!
  const _component_TableBody = _resolveComponent("TableBody")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.props.dialogVisible,
    title: "交易记录",
    width: "90%",
    "before-close": $setup.handleClose
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: $setup.handleClose
        }, {
          default: _withCtx(() => [
            _createTextVNode("确定")
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_button, { onClick: $setup.handleClose }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              md: 6,
              sm: 12
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "平仓时间：",
                  prop: "days"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: $setup.time,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.time) = $event)),
                      placeholder: "平仓时间",
                      onChange: $setup.getChange,
                      clearable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          label: "本周",
                          value: "本周"
                        }),
                        _createVNode(_component_el_option, {
                          label: "本月",
                          value: "本月"
                        }),
                        _createVNode(_component_el_option, {
                          label: "本年",
                          value: "本年"
                        }),
                        _createVNode(_component_el_option, {
                          label: "自定义",
                          value: "自定义"
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            ($setup.time == '自定义')
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  md: 8,
                  sm: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      style: {"width":"280px"},
                      type: "daterange",
                      modelValue: $setup.dateRange,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dateRange) = $event)),
                      onChange: $setup.changeDate,
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_el_col, {
              md: 6,
              sm: 12
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "货币符号：",
                  prop: "days"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: $setup.params1.symbol,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.params1.symbol) = $event)),
                      placeholder: "货币符号",
                      clearable: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.currentyList, (item, index) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            label: item.label,
                            value: item.value,
                            key: index
                          }, null, 8 /* PROPS */, ["label", "value"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_col, {
              md: 2,
              sm: 12
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  plain: "",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.doRefresh()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_TableBody, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_el_table, {
              data: $setup.dataList,
              "header-cell-style": $setup.tableConfig.headerCellStyle,
              size: $setup.tableConfig.size,
              stripe: $setup.tableConfig.stripe,
              border: $setup.tableConfig.border,
              height: $setup.tableConfig.height - 130,
              "show-summary": "",
              "summary-method": $setup.getSummaries
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "序号",
                  prop: "id"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "订单号",
                  prop: "order",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "货币符号",
                  prop: "symbol",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "交易类型",
                  prop: "cmd_label",
                  "min-width": "110"
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", {
                      style: _normalizeStyle({ color: row.cmd_label == 'Buy' ? 'red' : 'green' })
                    }, _toDisplayString(row.cmd_label), 5 /* TEXT, STYLE */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "平仓时间",
                  prop: "close_time",
                  "min-width": "110"
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString($setup.formatDate(row.close_time)), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "交易量",
                  prop: "volume",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "返佣标准",
                  prop: "user_commission_log.commission_standard",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "返佣金额$",
                  prop: "user_commission_log.commission",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "开仓价格",
                  prop: "open_price",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "止盈",
                  prop: "tp",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "止损",
                  prop: "sl",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "平仓价格",
                  prop: "close_price",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "隔夜利息",
                  prop: "storage",
                  "min-width": "110"
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  label: "盈亏",
                  prop: "profit",
                  "min-width": "110"
                }, {
                  default: _withCtx(({ row }) => [
                    _createCommentVNode(" 正数红色，负数绿色 "),
                    _createElementVNode("span", {
                      style: _normalizeStyle({ color: row.profit > 0 ? 'red' : 'green' })
                    }, _toDisplayString(row.profit), 5 /* TEXT, STYLE */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["data", "header-cell-style", "size", "stripe", "border", "height"])), [
              [_directive_loading, $setup.tableLoading]
            ])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_TableFooter, {
              ref: "tableFooter",
              onRefresh: $setup.doSearch,
              onPageChanged: $setup.doSearch
            }, null, 512 /* NEED_PATCH */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}