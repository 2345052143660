import LayoutStore from "@/layouts";
import Cookies from "js-cookie";

import Avatar from "@/assets/img_avatar.gif";
import { defineStore } from "pinia";
import { UserState } from "../types";
import {getWallet} from '@/api/url'
import { get } from '@/api/http'
import {
  USER_INFO_KEY,
  USER_TOKEN_KEY,
} from "../keys";

const defaultAvatar = Avatar;

const userInfo: UserState = JSON.parse(
  localStorage.getItem(USER_INFO_KEY) || "{}"
);

const useUserStore = defineStore("user", {
  state: () => {
    return {
      name: userInfo.name ? userInfo.name : '',
      phone: userInfo.phone ? userInfo.phone : '',
      rule_id: userInfo.rule_id ? userInfo.rule_id : "",
      avatar: defaultAvatar,
      last_login_ip: userInfo.last_login_ip ? userInfo.last_login_ip : "",
      last_login_at: userInfo.last_login_at ? userInfo.last_login_at : "",
      id_card: userInfo.id_card ? userInfo.id_card : "",
      parent_id: userInfo.last_login_at ? userInfo.parent_id : "",
      rule: userInfo.rule ? userInfo.rule :'',
    };
  },
  getters: {
  },
  actions: {
    saveUser(userInfo: UserState) {
      return new Promise<void>((res) => {
        this.name = userInfo.name;
        this.phone = userInfo.phone;
        this.rule_id = userInfo.rule_id;
        this.last_login_ip = userInfo.last_login_ip;
        this.last_login_at = userInfo.last_login_at;
        this.id_card = userInfo.id_card;
        this.parent_id = userInfo.parent_id;
        this.rule = userInfo.rule;
        localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
        res();
      });
    },
    // 获取钱包
    getWallet() {
      return get({
        url: getWallet,
      }).then((res: any) => {
        return res.data;
      });
    },
    logout() {
      return new Promise<void>((res) => {
        localStorage.clear();
        LayoutStore.reset();
        Cookies.remove(USER_TOKEN_KEY);
        res();
      });
    },
  },
});

export default useUserStore;


