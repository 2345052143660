import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18ca20c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-base-style" }
const _hoisted_2 = { class: "main-section" }
const _hoisted_3 = { class: "footer-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_Main = _resolveComponent("Main")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_el_backtop = _resolveComponent("el-backtop")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vaw-main-layout-container", [
    _ctx.state.layoutMode === 'ttb' ? 'main-layout__ttb' : '',
    _ctx.state.isFixedNavBar ? 'main-layout_fixed-nav-bar' : 'main-layout',
    _ctx.state.isCollapse ? 'main-layout-close-status' : 'main-layout-open-status',
  ]])
  }, [
    _createElementVNode("section", {
      class: _normalizeClass([
    _ctx.state.layoutMode === 'ttb'
      ? 'nav-bar__ttb'
      : !_ctx.state.isCollapse
        ? 'nav-bar-open-status'
        : 'nav-bar-close-status',
    _ctx.state.isFixedNavBar ? 'fixed-nav-bar' : '',
    !_ctx.mShowNavBar ? 'tab-bar-top' : '',
  ])
    }, [
      (_ctx.mShowNavBar)
        ? (_openBlock(), _createBlock(_component_NavBar, { key: 0 }))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" <TabBar /> ")
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_Main)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_Footer)
      ]),
      _createVNode(_component_el_backtop, { target: ".main-base-style" })
    ]),
    _createVNode(_component_el_backtop, { target: ".vaw-main-layout-container" })
  ], 2 /* CLASS */))
}