
import { defineComponent, PropType, ref } from "vue";
import {
  Plus as PlusIcon,
  Delete as DeleteIcon,
} from "@element-plus/icons";
import screenfull from "screenfull";
import { ElMessage } from "element-plus";

export default defineComponent({
  emits: ["refresh", "update:tableColumns", "update:border", "update:stripe"],
  components: {},
  props: {
    tableColumns: {
      type: Array as PropType<Array<TablePropsType>>,
      default: () => [] as Array<TablePropsType>,
    },
    border: {
      type: Boolean,
      default: false,
    },
    stripe: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const borderRef = ref(props.border);
    const stripeRef = ref(props.stripe);
    function doRefresh() {
      emit("refresh");
    }
    function onUpdateTable(newVal: Array<TablePropsType>) {
      emit("update:tableColumns", newVal);
    }
    function onFullScreen() {
      if (!screenfull.isEnabled) {
        ElMessage.error("当前浏览器不支持全屏操作");
        return false;
      }
      if (screenfull.isFullscreen) {
        screenfull.exit();
      } else {
        const element = document.getElementById("tableBody");
        if (element) {
          screenfull.request(element);
        }
      }
    }
    function onBorderClick() {
      borderRef.value = !borderRef.value;
      emit("update:border", borderRef.value);
    }
    function onStripClick() {
      stripeRef.value = !stripeRef.value;
      emit("update:stripe", stripeRef.value);
    }
    return {
      PlusIcon,
      DeleteIcon,
      borderRef,
      stripeRef,
      doRefresh,
      onUpdateTable,
      onFullScreen,
      onBorderClick,
      onStripClick,
    };
  },
});
