import { AxiosResponse } from 'axios'
import { App } from 'vue'
import request from './axios.config'
import Cookies from "js-cookie";
import {USER_TOKEN_KEY} from "../store/keys"
import { ElMessage } from 'element-plus'
import  router from '../router/index';

export interface HttpOption {
  url: string
  data?: any
  method?: string
  headers?: any
  beforeRequest?: () => void
  afterRequest?: () => void
}

export interface Response<T = any> {
  data: T,
  links?: any,
  meta?: any,
  proxy?: any,
  message?:'',
  count?: any,
}

function http<T = any>({
  url,
  data,
  method,
  headers,
  beforeRequest,
  afterRequest,
}: HttpOption) {
    headers = headers || {};
   headers.Authorization = `Bearer ${Cookies.get(USER_TOKEN_KEY)}`
  //  Accept
  headers.Accept = 'application/json'
  const successHandler = (res: AxiosResponse<Response<T>>) => {
    if (res.status === 200 || res.status === 201) {
    return res.data;
  }
   const errorMessage = res.data.message ? res.data.message : '请求失败，未知异常';
   ElMessage.error(errorMessage)
   throw new Error(errorMessage);
  }
  const failHandler = (error:any) => {
    let errorMessage = error.data;
    if(error.status === 401){
      errorMessage = '登录失效，请重新登录'
      router.push('/login')
    }else{
      errorMessage = error.data.message ? error.data.message : '请求失败，未知异常';
    }
    ElMessage.error(errorMessage)
    throw new Error(errorMessage);
  }
  beforeRequest && beforeRequest()
  method = method || 'GET'
  const params = Object.assign(
    typeof data === 'function' ? data() : data || {},
    {}
  )
  return method === 'GET'
  ? request.get(url, { params, headers }).then(successHandler, failHandler)
  : method === 'POST'
    ? request.post(url, params, { headers }).then(successHandler, failHandler)
    : method === 'PUT'
      ? request.put(url, params, { headers }).then(successHandler, failHandler)
      : method === 'DELETE'
        ? request.delete(url, { params, headers }).then(successHandler, failHandler)
       : throwError();
  
}
 const throwError = () => {
    throw new Error(`Unsupported method:`);
  };

export function get<T = any>({
  url,
  data,
  method = 'GET',
  beforeRequest,
  afterRequest,
}: HttpOption): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    beforeRequest,
    afterRequest,
  })
}
export function post<T = any>({
  url,
  data,
  method = 'POST',
  headers,
  beforeRequest,
  afterRequest,
}: HttpOption): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    beforeRequest,
    afterRequest,
  })
}
export function put<T = any>({
  url,
  data,
  method = 'PUT',
  headers,
  beforeRequest,
  afterRequest,
}: HttpOption): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    beforeRequest,
    afterRequest,
  })
}
// 添加del方法
export function del<T = any>({
  url,
  data,
  method = 'DELETE',
  headers,
  beforeRequest,
  afterRequest,
}: HttpOption): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    beforeRequest,
    afterRequest,
  })
}

function install(app: App): void {
  app.config.globalProperties.$http = http

  app.config.globalProperties.$get = get

  app.config.globalProperties.$post = post
  app.config.globalProperties.$put = put
  app.config.globalProperties.$del = del
}

export default {
  install,
  get,
  post,
  put,
  del,
}

declare module 'vue' {
  // 为 `this.$` 提供类型声明
  interface ComponentCustomProperties {
    $get: <T>(options: HttpOption) => Promise<Response<T>>
    $post: <T>(options: HttpOption) => Promise<Response<T>>
    $put: <T>(options: HttpOption) => Promise<Response<T>>
    $del: <T>(options: HttpOption) => Promise<Response<T>>
  }
}
